body {
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  text-decoration: none !important;
  text-decoration: none;
}
a:-webkit-any-link {
  text-decoration: none !important;
  color: #3fc4e0;
}

a:hover {
  text-decoration: none !important;
  color: #a62b30 !important;
}

.background {
  width: 100%;
  height: auto;
}

.header {
  background-color: #000;
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 2;
}

.headerMobile {
  background-color: #000;
  width: 100%;
  height: 57px;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 2;
}

.lineRed {
  position: absolute;
  top: 0;
  height: 5px;
}

.buy {
  position: absolute;
  top: 0;
  right: 0;
  height: 3.5rem;
  background: url(./images/buy.svg) no-repeat;
  min-width: 160px;
  max-width: 160px;
}

.buyMobile {
  position: absolute;
  top: 0;
  right: 0;
  height: 57px;
  background: url(./images/buyMobile.svg) no-repeat;
  min-width: 108px;
  max-width: 108px;
  z-index: 3;
}

.chooser {
  /* position: absolute; */
  margin-top: -11px;
  height: 3.75rem;
  background-image: url(./images/chooser-3.png);
  background-repeat: no-repeat;
  object-fit: fill;
  min-width: 572px;
  max-width: 572px;
  z-index: 2;
}

.chooserMobile {
  /* position: absolute; */
  margin-top: -4px;
  margin-left: -4px;
  background-image: url(./images/chooser-3-mobile.png);
  background-repeat: no-repeat;
  object-fit: fill;
  min-width: 223px;
  max-width: 223px;
  z-index: 2;
}
.bsc {
  margin: 18px 0px auto 65px;
}

.fantom {
  margin: 16px 0px auto 24px;
}
.metis {
  margin: 16px 8px auto 0px;
}

.bscMobile {
  margin: 8px 0px 0px 16px;
}

.fantomMobile {
  margin: 8px 0px 0px 8px;
}

.metisMobile {
  margin: 8px 0px 0px -4px;
}

.metisMobile {
  margin: 16px 0px 0px 6px;
}

.lineBlue {
  position: absolute;
  top: 3.6rem;
}

.lineBlueMobile {
  position: absolute;
  top: 48px;
  width: 100%;
  height: 3.75px;
  left: -75px;
}

.logoHeader {
  padding-left: 15px;
  margin-top: 3px;
  width: 66px;
  height: 66px;
  z-index: 2;
}

.logoHeaderMobile {
  margin-left: -15px;
  width: 35px;
  height: 35px;
  z-index: 2;
}

.menu {
  display: flex;
  justify-content: flex-end;
  z-index: 1;
}

.menu nav ul {
  display: flex;
}

.menu li {
  font-weight: bolder;
  color: #3fc4e0;
  margin-left: 1.8rem;
  list-style: none;
  font-size: 14px;
}

.menu a:focus {
  color: #a62b30 !important;
}

.menuTablet {
  display: flex;
  justify-content: center;
  z-index: 1;
}

.menuMobile {
  display: flex;
  justify-content: right;
  z-index: 1;
}

.menuMobile nav ul {
  display: flex;
}

.menuMobile li {
  font-weight: bolder;
  color: #3fc4e0;
  margin-left: 1.8rem;
  list-style: none;
  font-size: 10px;
}

.menuMobile a:focus {
  color: #a62b30 !important;
}

.footerHome {
  position: relative;
  bottom: 146px;
  height: 146px;
  z-index: 1;
}

.footerHomeMobile {
  position: relative;
  height: 166px;
  bottom: 166px;
  z-index: 1;
}
.footerR {
  position: absolute;
  right: 0;
}

.textoFooter {
  font-size: 18px;
  font-weight: bold;
}

.textoFooterMobile {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.2rem;
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 1.5rem;
}

.redesHome {
  right: 0;
  height: 66px;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  justify-items: center;
  background-image: url(./images/backFooterR.svg);
  background-repeat: no-repeat;
  object-fit: fill;
}

.redesHomeMobile {
  right: 0;
  height: 50px;
  width: 100%;
  max-width: 370px;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  justify-items: center;
  background-image: url(./images/backFooterRMobil.png);
  background-repeat: no-repeat;
  object-fit: fill;
  bottom: 0;
}

.tlvHome {
  left: 0;
  height: 66px;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  justify-items: center;
  background-image: url(./images/backFooterL.svg);
  background-repeat: no-repeat;
  object-fit: fill;
}

.textoTlvOne {
  font-family: "Oswald", sans-serif;
  font-size: 19px;
  color: #47cdeb;
  justify-content: left;
  text-transform: uppercase;
  line-height: 1;
  padding: 0 0.2rem 0 1rem;
}

.textoTlvOneMobile {
  font-family: "Oswald", sans-serif;
  font-size: 11px;
  color: #47cdeb;
  justify-content: left;
  text-transform: uppercase;
  line-height: 1;
  padding: 0 0.2rem 0 0.5rem;
}

.textoTlvTwo {
  font-family: "Oswald", sans-serif;
  font-size: 40px;
  color: #47cdeb;
  justify-content: left;
  text-transform: uppercase;
  padding: 0 1rem 0 0;
}

.textoTlvTwoMobile {
  font-family: "Oswald", sans-serif;
  font-size: 25px;
  color: #47cdeb;
  justify-content: left;
  text-transform: uppercase;
  padding: 0 0.5rem 0 0;
}

.textoTlvThree {
  font-family: "Oswald", sans-serif;
  font-size: 40px;
  color: #fff;
  justify-content: left;
  text-transform: uppercase;
}

.textoTlvThreeMobile {
  font-family: "Oswald", sans-serif;
  font-size: 25px;
  color: #fff;
  justify-content: left;
  text-transform: uppercase;
}

.tlvInfo {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
  max-width: 480px;
}

.tlvInfoMobile {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 100%;
}

.tlvRedesInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 420px;
}

.tlvRedesInfoMobile {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  max-width: 370px;
}

.tlvHomeMobile {
  left: 0;
  height: 50px;
  position: absolute;
  display: flex;
  justify-items: center;
  background-image: url(./images/backFooterLMobil.png);
  background-repeat: no-repeat;
  object-fit: fill;
  width: 100%;
  bottom: 50px;
}

.lineaHome {
  height: 66px;
  border-bottom: #47cdeb;
  border-style: solid;
  z-index: 1;
}

.lineaHomeMobile {
  height: 50px;
  border-bottom: #47cdeb;
  border-style: solid;
  background-color: #191919;
  z-index: 1;
}

.circulo {
  width: 40px !important;
  height: 40px;
  display: flex;
  justify-content: center;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #47cdeb;
  margin: auto 0.4rem;
}

.circulo a {
  font-size: 1.5rem;
  margin: auto;
  color: black;
}

.circuloMobile {
  width: 25px !important;
  height: 25px;
  display: flex;
  justify-content: center;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #47cdeb;
  margin: auto 0.2rem;
}

.circuloMobile a {
  font-size: 1rem;
  margin: auto;
  color: black;
}
.circuloMobile .svg-inline--fa {
  vertical-align: text-bottom;
}

.circulo .svg-inline--fa {
  vertical-align: text-bottom;
}

.headerDapps {
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
}

.backDapps {
  position: absolute;
  width: 100%;
}

.imageTitleRoad {
  position: absolute;
  left: 0;
}

.tokens {
  display: flex;
  min-height: 768px;
  align-items: center;
  position: relative;
}
.headerTokens {
  display: flex;
  width: 100%;
  position: relative;
  top: 0;
}

.divider {
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 1;
}

.titleHyper {
  margin: 2rem auto;
}

.titleTokens {
  width: 100%;
  display: flex;
  position: absolute;
  top: 0;
}

.dividerTokens {
  width: 100%;
  display: flex;
  position: absolute;
  bottom: -6px;
}

.imageTokens {
  position: absolute;
  left: 0;
}

.imageDivider {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.selections {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 100px;
}

.selectionsMobile {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 100px;
  flex-direction: column;
}

.listLinks {
  width: 50% !important;
  display: flex;
  justify-content: left;
  flex-direction: column;
  padding: 0 4rem;
}

.listLinksMobile {
  width: 100% !important;
  display: flex;
  justify-content: left;
  flex-direction: column;
  padding: 0 2rem;
}

.listSelections {
  display: flex;
  align-items: center;
  font-family: "Oswald", sans-serif;
  font-size: 38px !important;
  color: #fff;
  padding-bottom: 1.5rem;
  text-align: left;
}

.listBlue {
  font-size: 38px !important;
  color: #3fc4e0;
  margin-right: 5px;
}
.listBlueTwo {
  font-size: 38px !important;
  color: #3fc4e0;
}

.listBlueThree {
  font-size: 38px !important;
  color: #3fc4e0;
  margin-left: 5px;
}

.buttonsSelectionsMobile {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding-bottom: 1rem;
  text-align: left;
}

.listSelectionsMobile {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Oswald", sans-serif;
  font-size: 25px !important;
  color: #fff;
  padding-bottom: 1rem;
  text-align: left;
}

.listBlueMobile {
  font-size: 25px !important;
  color: #3fc4e0;
  margin-right: 5px;
  text-align: left;
}
.listBlueTwoMobile {
  font-size: 25px !important;
  color: #3fc4e0;
  text-align: left;
}

.listBlueThreeMobile {
  font-size: 25px !important;
  color: #3fc4e0;
  margin-left: 5px;
  text-align: left;
}

.panelInfo {
  width: 50% !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.panelInfoMobile {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 200px;
}

.listSelectionsMobile p {
  margin-top: 0;
  position: absolute;
}

.botonesPanelInfo {
  display: flex;
  padding-top: 20px;
}

.botonesPanelInfoMobile {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.mitad {
  width: 50% !important;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.textSelections {
  color: #0071a0;
  width: 485px;
  height: 140px;
  padding: 1.8rem 1rem;
  margin: 0 auto;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.textSelectionsMobile {
  color: #0071a0;
  width: 330px;
  height: 140px;
  padding: 1.8rem 1rem;
  margin: 0 auto;
  font-weight: 700;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.backSelections {
  width: 585px;
  height: 145px;
  background-image: url(./images/backWhy.png);
  background-repeat: no-repeat;
  object-fit: fill;
  z-index: 1;
}

.backSelectionsMobile {
  width: 350px;
  height: 147px;
  background-image: url(./images/backWhyMobile.png);
  background-repeat: no-repeat;
  object-fit: fill;
  z-index: 1;
  margin: 0 auto;
}

.cover-container {
  height: 100vh;
  width: 100%;
  object-fit: contain;
  /* box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.video {
  object-fit: cover;
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: -1;
  background-color: black;
}

.lineRoad {
  background-image: url(./images/lineVertical.svg);
  background-repeat: no-repeat;
  background-position: center center;
  height: 2300px;
}

.list {
  color: #3fc4e0;
  font-weight: bolder;
  border: 2px;
  border-style: solid;
  border-radius: 25px;
  border-color: #0071a0;
  padding: 1rem;
}

.list p {
  padding: 0.25rem 0;
}

.boxSlider {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.carousel .slide .legend {
  background-image: url(./images/backInfoDapps.png);
  background-repeat: no-repeat;
  background-position: center;
  width: 466px;
  height: 272px;
  bottom: 150px;
  background-color: revert;
  opacity: revert;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.carousel .slide .legend h1 {
  color: #0071a0;
  font-size: 48px;
  font-weight: bolder;
  font-family: "Oswald", sans-serif;
  text-align: left;
}

.carousel .slide .legend p {
  color: #0071a0;
  font-size: 15px;
  font-weight: bolder;
  padding: 0.5rem 1.5rem;
  text-align: left;
}

.buttonLengend {
  font-family: "Oswald", sans-serif;
  color: #fff !important;
  background-color: #0071a0 !important;
  border-radius: 50px !important;
  margin-left: 10px;
  font-weight: normal !important;
}

.carousel .control-dots .dot {
  width: 16px;
  height: 16px;
  border-radius: 0px;
  background: #80cce9;
  opacity: 10;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  background: #a62b30 !important;
}

.carousel .control-dots {
  position: absolute;
  bottom: 103px;
  left: 87px;
  width: 200px;
  text-align: center;
  z-index: 1;
}

.containerPanel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.elementsPanel {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-top: 100px;
}

.elementsPanelMobile {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  flex-direction: column;
}

.panel {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 700px;
}

.panelMobile {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 350px;
  height: 566px;
}

.containerInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 700px;
}
.containerInfoMobile {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 330px;
  height: 540px;
  flex-direction: column;
}
.infoPanel {
  z-index: 1;
  width: 50%;
  padding: 2rem 3rem;
}

.infoPanelMobile {
  z-index: 1;
  width: 78%;
  padding: 0 2.5rem 1rem 0.5rem;
}

.infoPanel h1 {
  font-size: 28px;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  color: #121212;
}

.infoPanel p {
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  color: #00709b;
}

.infoPanelMobile h1 {
  font-size: 18px;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  color: #121212;
}

.infoPanelMobile p {
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #00709b;
}

.linksPanel {
  display: flex;
  width: 100%;
  z-index: 1;
  align-items: center;
  margin-left: -40px;
}

.linksPanel img {
  margin-left: 5px;
}

.linksPanelMobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1;
  align-items: center;
  padding-top: 15px;
}

.linksPanelMobile img {
  margin: 0 0rem 0 2rem;
}

.panelSideTwo {
  z-index: 1;
  width: 50%;
  display: flex;
  flex-direction: column;
}

.panelSideTwoMobile {
  z-index: 1;
  width: 90%;
  display: flex;
  flex-direction: column;
}

.listPanel {
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding-top: 10px;
}

.listPanelMobile {
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding-top: 10px;
}

.containerList {
  display: flex;
  align-items: center;
  padding: 0 0 0.5rem 0.5rem;
}

.listPanel h1 {
  font-size: 20px;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  color: #00709b;
}

.listPanel p {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #fff;
}

.listPanelMobile h1 {
  font-size: 18px;
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  color: #00709b;
}

.listPanelMobile p {
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #fff;
}

.boxImage {
  width: 60px;
  height: 60px;
  margin-right: 15px;
  /* background-color: #A62B30; */
}

.boxInfoMobile {
  width: 90%;
  margin-right: 15px;
  /* background-color: pink; */
}

.boxImageMobile {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  /* background-color: #A62B30; */
}

.boxInfo {
  width: 70%;
  margin-right: 15px;
  /* background-color: pink; */
}

.lineHyper {
  position: relative;
  /* background-image: url(./images/lineHyper.png); */
  background-repeat: no-repeat;
  object-fit: fill;
  width: 300px;
  height: 313px;
  /* z-index: -2; */
}

.butonsTokens {
  position: relative;
}

.buttonHyper {
  top: 30px;
  left: 5px;
  position: absolute;
}
.buttonJump {
  top: 30px;
  right: 32px;
  position: absolute;
}

.buttonAlloy {
  top: 30px;
  right: 32px;
  position: absolute;
}

.buttonAurora {
  bottom: 20px;
  left: 5px;
  position: absolute;
}

.buttonOri {
  bottom: 20px;
  right: 32px;
  position: absolute;
}

.buttonsMobile {
  display: flex;
  z-index: 1;
  margin-top: -80px;
  margin-bottom: 30px;
}

/* Media queries */

@media only screen and (max-width: 30em) {
  .carousel .slide .legend {
    background-image: url(./images/backInfoDappsMobile_2.png);
    background-repeat: no-repeat;
    background-position: center;
    width: 350px;
    bottom: 50px;
    background-color: revert;
    opacity: revert;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 50%;
    margin-left: -48%;
  }

  .carousel .slide .legend h1 {
    color: #0071a0;
    font-size: 24px;
    font-weight: bolder;
    font-family: "Oswald", sans-serif;
    text-align: left;
  }

  .carousel .slide .legend p {
    color: #0071a0;
    font-size: 12px;
    font-weight: 600;
    padding: 0.5rem 1.5rem;
    text-align: left;
  }
  .carousel .control-dots {
    bottom: 33px;
    left: 25px;
    width: 200px;
  }

  .tokens {
    display: flex;
    min-height: 850px;
    align-items: center;
    position: relative;
  }

  .backWhy {
    background-image: url(./backgrounds/backWhyMobile.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

@media screen and (min-width: 48em) {
  .carousel .slide .legend {
    background-image: url(./images/backInfoDappsMobile_2.png);
    background-repeat: no-repeat;
    background-position: center;
    width: 350px;
    bottom: 50px;
    background-color: revert;
    opacity: revert;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 50%;
    margin-left: -50%;
  }

  .carousel .slide .legend h1 {
    color: #0071a0;
    font-size: 24px;
    font-weight: bolder;
    font-family: "Oswald", sans-serif;
    text-align: left;
  }

  .carousel .slide .legend p {
    color: #0071a0;
    font-size: 12px;
    font-weight: 600;
    padding: 0.5rem 1.5rem;
    text-align: left;
  }
  .carousel .control-dots {
    bottom: 33px;
    left: 25px;
    width: 200px;
  }

  .tokens {
    display: flex;
    min-height: 850px;
    align-items: center;
    position: relative;
  }
  .backWhy {
    background-image: url(./backgrounds/backWhyMobile.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

@media screen and (max-width: 48em) {
  .carousel .slide .legend {
    background-image: url(./images/backInfoDappsMobile_2.png);
    background-repeat: no-repeat;
    background-position: center;
    width: 350px;
    bottom: 50px;
    background-color: revert;
    opacity: revert;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 50%;
    margin-left: -50%;
  }

  .carousel .slide .legend h1 {
    color: #0071a0;
    font-size: 24px;
    font-weight: bolder;
    font-family: "Oswald", sans-serif;
    text-align: left;
  }

  .carousel .slide .legend p {
    color: #0071a0;
    font-size: 12px;
    font-weight: 600;
    padding: 0.5rem 1.5rem;
    text-align: left;
  }
  .carousel .control-dots {
    bottom: 33px;
    left: 25px;
    width: 200px;
  }

  .tokens {
    display: flex;
    min-height: 850px;
    align-items: center;
    position: relative;
  }
  .backWhy {
    background-image: url(./backgrounds/backWhyMobile.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

@media only screen and (min-width: 62em) {
  .carousel .slide .legend {
    background-image: url(./images/backInfoDapps.png);
    background-repeat: no-repeat;
    background-position: center;
    width: 466px;
    height: 272px;
    bottom: 150px !important;
    background-color: revert;
    opacity: revert;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 55%;
    margin-left: -50%;
  }

  .carousel .slide .legend h1 {
    color: #0071a0;
    font-size: 48px;
    font-weight: bolder;
    font-family: "Oswald", sans-serif;
    text-align: left;
  }

  .carousel .slide .legend p {
    color: #0071a0;
    font-size: 15px;
    font-weight: bolder;
    padding: 0.5rem 1.5rem;
    text-align: left;
  }
  .carousel .control-dots {
    position: absolute;
    bottom: 103px;
    left: 87px;
    width: 200px;
    text-align: center;
    z-index: 1;
  }
  .backWhy {
    background-image: url(./backgrounds/backWhy.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .backSelections {
    width: 390px;
    height: 145px;
    background-image: url(./images/backWhy_385.png);
    background-repeat: no-repeat;
    object-fit: fill;
    z-index: 1;
  }
  .textSelections {
    color: #0071a0;
    width: 370px;
    height: 140px;
    padding: 1.8rem 1rem;
    margin: 0 auto;
    font-weight: 700;
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  .listSelections {
    font-size: 30px !important;
  }

  .listBlue {
    font-size: 30px !important;
  }
  .listBlueTwo {
    font-size: 30px !important;
  }

  .listBlueThree {
    font-size: 30px !important;
  }
}

@media only screen and (min-width: 80em) {
  .carousel .slide .legend {
    background-image: url(./images/backInfoDapps.png);
    background-repeat: no-repeat;
    background-position: center;
    width: 466px;
    height: 272px;
    bottom: 150px;
    background-color: revert;
    opacity: revert;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .carousel .slide .legend h1 {
    color: #0071a0;
    font-size: 48px;
    font-weight: bolder;
    font-family: "Oswald", sans-serif;
    text-align: left;
  }

  .carousel .slide .legend p {
    color: #0071a0;
    font-size: 15px;
    font-weight: bolder;
    padding: 0.5rem 1.5rem;
    text-align: left;
  }
  .carousel .control-dots {
    position: absolute;
    bottom: 105px;
    left: 87px;
    width: 200px;
    text-align: center;
    z-index: 1;
  }
  .backWhy {
    background-image: url(./backgrounds/backWhy.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .backSelections {
    width: 585px;
    height: 145px;
    background-image: url(./images/backWhy.png);
    background-repeat: no-repeat;
    object-fit: fill;
    z-index: 1;
  }

  .textSelections {
    color: #0071a0;
    width: 485px;
    height: 140px;
    padding: 1.8rem 1rem;
    margin: 0 auto;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  .listBlue {
    font-size: 38px !important;
    color: #3fc4e0;
    margin-right: 5px;
  }
  .listBlueTwo {
    font-size: 38px !important;
    color: #3fc4e0;
  }

  .listBlueThree {
    font-size: 38px !important;
    color: #3fc4e0;
    margin-left: 5px;
  }
  .listSelections {
    font-size: 38px !important;
  }
}

/* End Media queries */
